import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-4 text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "max-w-md mx-auto my-4 text-xl tracking-tight text-center text-gray-900 sm:text-2xl" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "w-full px-4 mx-auto -space-y-px bg-white md:px-0 md:w-96" }
const _hoisted_6 = ["name", "onClick"]
const _hoisted_7 = { class: "flex flex-col ml-3" }
const _hoisted_8 = { class: "block text-sm font-medium" }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Progress = _resolveComponent("Progress")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.$store.state.quiz.hide_title)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.$store.state.quiz.title), 1))
      : _createCommentVNode("", true),
    (this.loading)
      ? (_openBlock(), _createBlock(_component_Loading, {
          key: 1,
          class: "py-8"
        }))
      : _createCommentVNode("", true),
    (!this.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Progress, {
            class: "w-64 mx-auto",
            current: _ctx.$store.state.current + 1
          }, null, 8, ["current"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.quiz.questions, (question, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass({ hidden: index !== _ctx.$store.state.current }),
              key: (question.id, index)
            }, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(question.title), 1),
              _createElementVNode("fieldset", null, [
                _createElementVNode("legend", _hoisted_4, _toDisplayString(question.title), 1),
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.answer_options, (item) => {
                    return (_openBlock(), _createElementBlock("label", {
                      key: item.id,
                      class: "relative flex p-4 border cursor-pointer focus:outline-none"
                    }, [
                      _createElementVNode("input", {
                        type: "radio",
                        name: question.title,
                        onClick: ($event: any) => (_ctx.storeAnswer(question.id, item.points, item.id)),
                        class: "h-4 mt-0.5 cursor-pointer border-gray-300"
                      }, null, 8, _hoisted_6),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(item.title), 1)
                      ])
                    ]))
                  }), 128)),
                  (_ctx.$store.state.current !== 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("button", {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.prevQuestion())),
                          type: "button",
                          class: "inline-flex items-center px-4 py-2 mt-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                        }, _cache[1] || (_cache[1] = [
                          _createElementVNode("svg", {
                            xmlns: "http://www.w3.org/2000/svg",
                            class: "mr-2",
                            width: "1rem",
                            height: "1rem",
                            fill: "none",
                            viewBox: "0 0 24 24",
                            stroke: "currentColor"
                          }, [
                            _createElementVNode("path", {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "2",
                              d: "M11 17l-5-5m0 0l5-5m-5 5h12"
                            })
                          ], -1),
                          _createTextVNode(" Terug ")
                        ]))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}