
import { defineComponent } from "vue";
import store from "../store";
import Loading from "./Loading.vue";

export default defineComponent({
  name: "ResultScreen",
  components: {
    Loading,
  },
  data() {
    return {
      name: "",
      email: "",
      failed: 0,
      color: "",
      textColor: "",
    };
  },
  plugins: [require("@tailwindcss/forms")],
  methods: {
    async getResults() {
      let postData;

      const score = store.getters.totalScore;

      if (store.state.quiz.optIn) {
        postData = JSON.stringify({
          quiz: store.state.quizId,
          answers: store.getters.selectedAnswers,
          score: score,
          email: this.email,
          firstname: this.name,
        });
      } else {
        postData = JSON.stringify({
          quiz: store.state.quizId,
          answers: store.getters.selectedAnswers,
          score: score,
        });
      }

      await fetch(process.env.VUE_APP_API_URL + "/quiz", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: postData,
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if ("url" in data) {
              window.top.location.href = data.url;
            }
          });
        } else {
          if (this.failed < 3) {
            alert(
              "Oeps, het lijkt erop alsof er iets is mis gegaan, controleer je gegevens en probeer het opnieuw."
            );
            this.failed++;
          } else {
            alert(
              "Sorry, wij hebben het jouw antwoorden niet kunnen verwerken. Vernieuw de pagina om het opnieuw te proberen."
            );
            location.reload();
          }
        }
      });
    },
    getColor() {
      var color = store.state.quiz.color;
      if (color === null) {
        return;
      }

      if (color.length < 6) {
        return;
      }

      this.color = color;
      this.getContrast(color);
      return;
    },
    getContrast(hexcolor: string) {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;

      if (yiq >= 128) {
        this.textColor = "#000000";
      } else {
        this.textColor = "#ffffff";
      }

      return;
    },
    prevQuestion() {
      store.commit("syncCurrent", false);
    },
  },
  created() {
    // Skip screen if option is selected
    if (store.state.quiz.skipEndScreen) {
      this.getResults();
    }

    this.getColor();
    return;
  },
});
