import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative pt-1" }
const _hoisted_2 = { class: "flex h-2 overflow-hidden text-xs bg-gray-200 rounded" }
const _hoisted_3 = { class: "w-full text-center" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle({ width: _ctx.progress + '%', backgroundColor: _ctx.color }),
        class: "flex flex-col justify-center text-center text-white transition-all bg-blue-500 shadow-none whitespace-nowrap"
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.current) + " van " + _toDisplayString(_ctx.$store.state.quiz.totalQuestions), 1)
    ])
  ]))
}