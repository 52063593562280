import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full max-w-md space-y-8" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-4 text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "-space-y-px rounded-md shadow-sm" }
const _hoisted_8 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$store.state.quiz.skipEndScreen)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_3, [
              (_ctx.$store.state.quiz.custom_completed_text === '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Quiz voltooid!"))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$store.state.quiz.custom_completed_text), 1))
            ])
          ]),
          _createElementVNode("form", {
            class: "mt-8 space-y-6",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.getResults && _ctx.getResults(...args)), ["prevent"]))
          }, [
            (_ctx.$store.state.quiz.optIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createElementVNode("p", { class: "my-2 text-center text-gray-600 text-md" }, " Vul de onderstaande velden in om jouw uitslag te bekijken ", -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("input", {
                    type: "hidden",
                    name: "remember",
                    value: "true"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _cache[4] || (_cache[4] = _createElementVNode("label", {
                        for: "fistname",
                        class: "sr-only"
                      }, "Voornaam", -1)),
                      _withDirectives(_createElementVNode("input", {
                        id: "fistname",
                        name: "Voornaam",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
                        type: "text",
                        required: "",
                        class: "relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm",
                        placeholder: "Voornaam"
                      }, null, 512), [
                        [_vModelText, _ctx.name]
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("label", {
                        for: "email-address",
                        class: "sr-only"
                      }, "E-mailadres", -1)),
                      _withDirectives(_createElementVNode("input", {
                        id: "email-address",
                        name: "email",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                        type: "email",
                        autocomplete: "email",
                        required: "",
                        class: "relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm",
                        placeholder: "E-mailadres"
                      }, null, 512), [
                        [_vModelText, _ctx.email]
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                type: "submit",
                style: _normalizeStyle({ backgroundColor: _ctx.color, color: _ctx.textColor }),
                class: "relative flex justify-center w-full px-4 py-2 text-sm font-bold text-white bg-blue-600 border border-transparent rounded-md group focus:outline-none"
              }, " Bekijk de uitslag ", 4)
            ])
          ], 32),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.prevQuestion())),
              type: "button",
              class: "mt-8 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50"
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                class: "mr-2",
                width: "1rem",
                height: "1rem",
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M11 17l-5-5m0 0l5-5m-5 5h12"
                })
              ], -1),
              _createTextVNode(" Terug ")
            ]))
          ])
        ]))
  ]))
}