
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorPage",
  props: {
    code: {
      Type: String,
      default: "404",
    },
    msg: {
      Type: String,
      default: "Quiz niet gevonden",
    },
  },
});
