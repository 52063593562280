
import { defineComponent } from "vue";
import Progress from "./Progress.vue";
import Loading from "./Loading.vue";
import store from "../store";

export default defineComponent({
  name: "Quiz",
  data() {
    return {
      loading: false,
    };
  },
  components: {
    Progress,
    Loading,
  },
  methods: {
    storeAnswer(question: number, points: number, answerId: number) {
      // Set points for question
      store.commit("storePoints", [question, points, answerId]);
      this.nextQuestion();
    },
    nextQuestion() {
      store.commit("syncCurrent", true);
    },
    prevQuestion() {
      store.commit("syncCurrent", false);
    },
  },
});
