
import { defineComponent } from "vue";
import store from "../store";

export default defineComponent({
  name: "Progress",
  data() {
    return {
      progress: 0,
      step: 100,
      color: "",
    };
  },
  props: {
    current: {
      Type: Number,
      default: 1,
    },
  },
  methods: {
    async calculateProgress(value: number) {
      if (this.step === 100) {
        this.step = 100 / store.state.quiz.totalQuestions;
      }

      this.progress = this.step * value;
      return;
    },
    getColor() {
      var color = store.state.quiz.color;
      if (color === null) {
        return;
      }

      if (color.length < 6) {
        return;
      }

      this.color = color;
      return;
    },
  },
  watch: {
    current: function (newValue) {
      this.calculateProgress(newValue);
    },
  },
  created() {
    this.calculateProgress(this.current);
    this.getColor();
    return;
  },
});
