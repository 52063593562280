import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-3xl min-h-screen px-4 py-16 mx-auto bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ErrorPage = _resolveComponent("ErrorPage")!
  const _component_Quiz = _resolveComponent("Quiz")!
  const _component_ResultScreen = _resolveComponent("ResultScreen")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.loading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$store.state.error.active && !this.loading)
      ? (_openBlock(), _createBlock(_component_ErrorPage, {
          key: 1,
          code: _ctx.$store.state.error.code,
          msg: _ctx.$store.state.error.msg
        }, null, 8, ["code", "msg"]))
      : _createCommentVNode("", true),
    (
        !_ctx.$store.state.error.active &&
        _ctx.$store.state.quiz.totalQuestions > 0 &&
        !_ctx.$store.getters.quizEnd &&
        !this.loading
      )
      ? (_openBlock(), _createBlock(_component_Quiz, { key: 2 }))
      : _createCommentVNode("", true),
    (
        _ctx.$store.getters.quizEnd && !_ctx.$store.state.error.active && !this.loading
      )
      ? (_openBlock(), _createBlock(_component_ResultScreen, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}