
import { defineComponent } from "vue";
import store from "./store";
import Loading from "./components/Loading.vue";
import ErrorPage from "./components/ErrorPage.vue";
import Quiz from "./components/Quiz.vue";
import ResultScreen from "./components/ResultScreen.vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Loading,
    ErrorPage,
    Quiz,
    ResultScreen,
  },
  methods: {
    parseQuizId() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      return params.get("q") ?? "";
    },

    async validateQuizId(q: string) {
      if (q === "") {
        await store.commit("setQuizStatus", false);
        return;
      }
      await store.dispatch("getQuizData");
    },

    async loadQuiz() {
      let q: string;
      let valid: boolean;
      q = this.parseQuizId();
      await store.commit("storeQuizId", q);
      await this.validateQuizId(q);

      valid = store.state.status;

      if (!valid) {
        let errorData = {
          active: true,
          code: 404,
          msg: "quiz niet gevonden",
        };

        await store.commit("storeErrorData", errorData);

        this.loading = false;
        return;
      }

      store.commit("storeQuizId", q);
      this.loading = false;
      return;
    },
  },
  created() {
    // Get Quiz ID
    this.loadQuiz();
  },
});
