import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mx-auto max-w-max" }
const _hoisted_2 = { class: "sm:flex" }
const _hoisted_3 = {
  key: 0,
  class: "text-4xl font-extrabold text-blue-600 sm:text-5xl"
}
const _hoisted_4 = { class: "sm:ml-6" }
const _hoisted_5 = { class: "sm:border-l sm:border-gray-200 sm:pl-6" }
const _hoisted_6 = { class: "text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      (this.code > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(this.code), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h1", _hoisted_6, _toDisplayString(this.msg), 1)
        ])
      ])
    ])
  ]))
}